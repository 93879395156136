import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar parametro calculo';
topicoCreate.descricao = 'Criar parâmetro de cálculo';
topicoCreate.path = 'admin/tipos-parametros-calculo/\\d+/parametro-calculo/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar parametros calculo';
topicoList.descricao = 'Listar parâmetros de cálculo';
topicoList.path = 'admin/tipos-parametros-calculo/\\d+/parametro-calculo/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar parametro calculo';
topicoEdit.descricao = 'Editar parâmetro de cálculo';
topicoEdit.path = 'admin/tipos-parametros-calculo/\\d+/parametro-calculo/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
