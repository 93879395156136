import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar modalidade status calculo';
topicoCreate.descricao = 'Criar modalidade de status de cálculo';
topicoCreate.path = 'admin/modalidade-status-calculo/editar/criar$|tarefa/\\d+/pessoa-devedor/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar modalidades status calculo';
topicoList.descricao = 'Listar modalidades de status de cálculo';
topicoList.path = 'admin/modalidade-status-calculo/listar$|tarefa/\\d+/pessoa-devedor/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar modalidade status calculo';
topicoEdit.descricao = 'Editar modalidade de status de cálculo';
topicoEdit.path = 'admin/modalidade-status-calculo/editar/\\d+$|tarefa/\\d+/pessoa-devedor/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
