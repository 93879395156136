import {CriteriaDataInterface} from '@cdk/components/criteria/contracts/criteria-data.interface';
import {AbstractOperator} from '@cdk/components/criteria/operators/abstract-operator';
import {
    ExpressionPositionLeft,
    ExpressionPositionMiddle, ExpressionPositionRight,
    ExpressionPositions
} from '@cdk/components/criteria/operators/index';

export abstract class AbstractComparisonOperator extends AbstractOperator
{
    /**
     * @param literalOperator string
     * @param expressionPosition string
     */
    constructor(
        protected readonly literalOperator: string,
        protected readonly expressionPosition: ExpressionPositions = ExpressionPositionMiddle
    ) {
        super();
    }

    /**
     * Returns criteria data.
     * @param field string
     * @param value string|number
     */
    public getCriteria(field: string, value: string|number): CriteriaDataInterface {
        let criteria: CriteriaDataInterface = {};
        criteria[field] = this.expression.replace('arg', value as string)
        return criteria;
    }

    /**
     * Returns literal expression.
     * @param fieldName string
     * @param value     string|number
     * @param withHtml  boolean
     */
    public getLiteralExpression(fieldName: string, value: string|number = '', withHtml: boolean = true): string {
        let literalOperator: string = this.literalOperator;
        if (withHtml) {
            literalOperator = `<span class="criteria-comparison-operator">${literalOperator}</span>`
            fieldName = `<span class="criteria-field-name">${fieldName}</span>`;
            if (value) {
                value = `<span class="criteria-value">${value}</span>`;
            }
        }
        switch (this.expressionPosition) {
            case ExpressionPositionLeft:
                return `${literalOperator} ${fieldName} ${value}`;
            case ExpressionPositionMiddle:
                return `${fieldName} ${literalOperator} ${value}`;
            case ExpressionPositionRight:
                return `${fieldName} ${value} ${literalOperator}`;
            default:
                throw new Error('Unsuported expression position')
        }
    }

    /**
     * Returns literal operator.
     */
    public getLiteralOperator(): string {
        return this.literalOperator;
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public abstract checkExpression(expression: string): boolean;

    /**
     * Returns expression value.
     * @param expression
     */
    public abstract getValueFromExpression(expression: string): string;
}
