import {Exclude, Transform, Type} from 'class-transformer';
import {
    AssuntoAdministrativo,
    Documento,
    EspecieDocumento,
    EspecieProcesso,
    EspecieSetor,
    ModalidadeOrgaoCentral,
    Modelo,
    Repositorio,
    Usuario,
    Tese
} from '@cdk/models';
import * as moment from 'moment';
export class VinculacaoOrgaoCentralMetadados {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Type(() => Tese)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    tese?: Tese;

    @Type(() => ModalidadeOrgaoCentral)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeOrgaoCentral?: ModalidadeOrgaoCentral;

    @Type(() => Documento)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    documento?: Documento;

    @Type(() => Modelo)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modelo?: Modelo;

    @Type(() => Repositorio)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    repositorio?: Repositorio;

    @Type(() => AssuntoAdministrativo)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    assuntoAdministrativo?: AssuntoAdministrativo;

    @Type(() => EspecieSetor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    especieSetor?: EspecieSetor;

    @Type(() => EspecieProcesso)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    especieProcesso?: EspecieProcesso;

    @Type(() => EspecieDocumento)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    especieDocumento?: EspecieDocumento;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    criadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    criadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    atualizadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    atualizadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    apagadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.tese = null;
        this.modalidadeOrgaoCentral = null;
        this.documento = null;
        this.modelo = null;
        this.repositorio = null;
        this.assuntoAdministrativo = null;
        this.especieSetor = null;
        this.especieProcesso = null;
        this.especieDocumento = null;
        this.especieDocumento = null;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
