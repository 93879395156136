import {Exclude} from 'class-transformer';

export class MagistradoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    cpf: string;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.cpf = null;
    }
}
