import * as moment from 'moment';
import {Type, Transform, Exclude} from 'class-transformer';
import {OrgaoJulgador} from './orgao-julgador.model';
import {Setor} from '@cdk/models';
import {ModalidadeComunicacaoJudicial, ComunicacaoJudicial} from './';

export class AvisoComunicacaoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    numero: string;

    fonteDados?: string;

    idAviso?: string;

    idConsultante?: string;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraDisponibilizacao?: moment.Moment;

    pessoa?: string;

    numeroDocumentoPrincipal?: string;

    @Type(() => OrgaoJulgador)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    orgaoJulgador?: OrgaoJulgador;

    @Type(() => ModalidadeComunicacaoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeComunicacaoJudicial: ModalidadeComunicacaoJudicial;

    @Type(() => ComunicacaoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    comunicacaoJudicial?: ComunicacaoJudicial;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    unidade?: Setor;

    idMovimento?: string;

    ativo: boolean;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.numero = null;
        this.fonteDados = null;
        this.idAviso = null;
        this.idConsultante = null;
        this.dataHoraDisponibilizacao = null;
        this.pessoa = null;
        this.numeroDocumentoPrincipal = null;
        this.orgaoJulgador = null;
        this.modalidadeComunicacaoJudicial = null;
        this.comunicacaoJudicial = null;
        this.unidade = null;
        this.idMovimento = null;
        this.ativo = true;
    }
}
