import * as moment from 'moment';
import {Type, Transform, Exclude} from 'class-transformer';

import {Pessoa, Processo, Setor, Usuario} from '@cdk/models';
import {ProcessoJudicial} from './';
import {ModalidadeRisco} from './modalidade-risco.model';

export class VinculacaoProcessoJudicialProcesso {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    processoJudicial?: ProcessoJudicial;

    @Type(() => Processo)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    processo?: Processo;

    @Type(() => Pessoa)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    pessoaRepresentada?: Pessoa;

    @Type(() => ModalidadeRisco)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    modalidadeRisco: ModalidadeRisco;

    @Exclude({ toClassOnly: true })
    @Type(() => Setor)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    setorAtual?: Setor;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format() : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    criadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    apagadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.processoJudicial = null;
        this.processo = null;
        this.pessoaRepresentada = null;
        this.modalidadeRisco = null;
        this.setorAtual = null;
        this.criadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
