import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar configuracao tipo indicador';
topicoCreate.descricao = 'Criar configuração de tipo de indicador';
topicoCreate.path = 'admin/tipo-indicador/\\d+/configuracao-tipo-indicador/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar configurações tipo indicador';
topicoList.descricao = 'Listar configurações de tipos de indicador';
topicoList.path = 'admin/tipo-indicador/\\d+/configuracao-tipo-indicador/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar configuracao tipo indicador';
topicoEdit.descricao = 'Editar configuração tipo de indicador';
topicoEdit.path = 'admin/tipo-indicador/\\d+/configuracao-tipo-indicador/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
