<mat-toolbar>
    <mat-toolbar-row class="w-full" *ngIf="activeCard === 'chat-list'">
        <form [formGroup]="pesquisaForm" name="pesquisaForm" tabindex="-1"
              (ngSubmit)="pesquisar()"
              (keydown.enter)="pesquisar(); $event.preventDefault();">

            <div class="search-wrapper flex flex-row justify-start items-center content-center flex-1-1-0">

                <div class="search flex flex-row justify-start items-center content-center flex-1-1-0">
                    <mat-icon class="text-base h-16 w-16 leading-none text-secondary">search</mat-icon>
                    <input matNativeControl class="flex grow shrink basis-auto w-200" formControlName="pesquisa" placeholder="Pesquisar" tabindex="-1">
                </div>
            </div>
            <div>
                <button mat-icon-button tabindex="-1"
                        class="w-32 h-32 text-4xl leading-none mr-1"
                        [matMenuTriggerFor]="userMenu"
                        aria-label="more">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu">
                    <button mat-menu-item (click)="novaConversa()">
                        Nova conversa
                    </button>
                    <button mat-menu-item (click)="criarGrupo()">
                        Criar grupo
                    </button>
                </mat-menu>
            </div>
        </form>
    </mat-toolbar-row>
    <mat-toolbar-row class="w-full" *ngIf="activeCard === 'chat-individual-form'">
        <form [formGroup]="usuarioForm" name="usuarioForm"
              (ngSubmit)="iniciarChat()"
              (keydown.enter)="iniciarChat(); $event.preventDefault();">
            <button mat-icon-button class="w-32 h-32 leading-none text-4xl" (click)="cancelUsuarioForm()">
                <mat-icon class="text-secondary">arrow_back</mat-icon>
            </button>
            <div class="search-wrapper flex flex-row justify-start items-center content-center flex-1-1-0">

                <div class="search flex flex-row justify-start items-center content-center flex-1-1-0">
                    <mat-icon class="text-base h-16 w-16 leading-none text-secondary">search</mat-icon>
                    <input #usuarioInput
                           matNativeControl
                           tabindex="-1"
                           shouldLabelFloat="false"
                           class="flex grow shrink basis-auto w-168"
                           placeholder="Pesquisar usuário..."
                           formControlName="usuario"
                           [matAutocomplete]="usuario.autocomplete"
                           (blur)="checkUsuario()"
                           required>
                </div>
            </div>
            <cdk-usuario-autocomplete
                #usuario="usuarioAutocomplete"
                [control]="usuarioForm.get('usuario')"
                [pagination]="usuarioFormPagination">
            </cdk-usuario-autocomplete>
            <button type="submit" class="w-32 h-32 text-4xl leading-none" mat-icon-button
                    [disabled]="usuarioForm.invalid">
                <mat-icon class="text-secondary">done</mat-icon>
            </button>
        </form>
    </mat-toolbar-row>
</mat-toolbar>
