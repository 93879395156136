import {Type, Transform, Exclude} from 'class-transformer';
import {ProcessoJudicial, ParametroJudicial} from './';

export class VinculacaoParametroJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Type(() => ParametroJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    parametroJudicial: ParametroJudicial;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial: ProcessoJudicial;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.parametroJudicial = null;
        this.processoJudicial = null;
    }
}
