import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Principal Parcelas';
topico.descricao = 'Parâmetros de cálculo principal parcelas';
topico.path = 'tarefa/\\d+/calculo/\\d+/elaborar/interessado/\\d+/[a-z]+/principal-parcelas$';
topico.module = (): Promise<any> => import('./ajuda-calculo-elaborar-principal-parcelas.module').then(m => m.AjudaCalculoElaborarPrincipalParcelasModule);

export const topicosConfig =
    [
        topico
    ];
