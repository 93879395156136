const protocol = location.protocol; // https:
const basename = location.hostname.split('.')[0]; // supp, supp-int, supp-homol ou supp-treina...
const basenameNamespaceSafe =  'supp'; //  basename.lastIndexOf('-') > 0 ? basename.substring(0, basename.lastIndexOf('-')) : basename; //supp
const url = '.procuradoria.go.gov.br'; // location.hostname.replace(basenameNamespaceSafe, '').replace('frontend','backend');
// .agu.gov.br, -int.subdomin.agu.gov.br, -homol.subdomin.agu.gov.br, -treina.subdomin.agu.gov.br

// http://supp-backend.colocation-pge-supp-frontend.apps.dc1openshiftprod.cloud.go.gov.br/config


export const environment = {
    test: false,
    production: true,
    base_url: protocol + '//' + basenameNamespaceSafe + '-backend' + url + '/',
    api_url: protocol + '//' + basenameNamespaceSafe + '-backend' + url + '/v1/',
    mercure_hub: protocol + '//' + basenameNamespaceSafe + '-mercure' + url + '/.well-known/mercure',
    xdebug: '',
    barramento: false
};
