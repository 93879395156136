<mat-toolbar>

    <div class="flex flex-row justify-center items-center content-center sm:space-between sm:items-center">

        <div class="flex flex-row justify-start items-center content-center xs:hidden">
            <!--
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
            -->
        </div>

    </div>

</mat-toolbar>
