import {Type, Transform, Exclude} from 'class-transformer';
import {ProcessoJudicial, ComunicacaoJudicial} from './';
import { Etiqueta, ModalidadeOrgaoCentral, Setor, Usuario } from '@cdk/models';
import * as moment from 'moment';

export class VinculacaoEtiquetaJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Exclude({ toPlainOnly: true })
    label?: string;

    @Exclude({ toPlainOnly: true })
    objectClass?: string;

    @Exclude({ toPlainOnly: true })
    objectUuid?: string;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraExpiracao?: moment.Moment;

    @Type(() => Etiqueta)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    etiqueta?: Etiqueta;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    usuario?: Usuario;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    setor?: Setor;

    @Type(() => ModalidadeOrgaoCentral)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeOrgaoCentral?: ModalidadeOrgaoCentral;

    @Type(() => ComunicacaoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    comunicacaoJudicial?: ComunicacaoJudicial;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial?: ProcessoJudicial;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    criadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    criadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    atualizadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    atualizadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    apagadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    apagadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    podeAlterarConteudo?: boolean;

    @Exclude({toPlainOnly: true})
    podeExcluir?: boolean;

    conteudo?: string;

    privada?: boolean;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.conteudo = null;
        this.privada = null;
        this.dataHoraExpiracao = null;
        this.comunicacaoJudicial = null;
        this.processoJudicial = null;
        this.etiqueta = null;
        this.usuario = null;
        this.setor = null;
        this.modalidadeOrgaoCentral = null;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
        this.label = null;
        this.podeAlterarConteudo = null;
        this.podeExcluir = null;
        this.objectClass = null;
        this.objectUuid = null;
    }
}
