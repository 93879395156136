import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';

import {cdkAnimations} from '@cdk/animations';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'cdk-columns-select',
    templateUrl: './cdk-columns-select.component.html',
    styleUrls: ['./cdk-columns-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: cdkAnimations,
    exportAs: 'columnsSelect',
})
export class CdkColumnsSelectComponent {

    @Input()
    formCtrl: FormControl;

    @Input()
    tooltip: string;

    @Input()
    idField: string;

    @Input()
    labelField: string;

    @Input()
    hideOptionField: string = '';

    @Input()
    showReset: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    elements: any[] = [];

    @Input()
    alturaMenu: number = 256;

    @Output()
    resetConfigEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    clickOption($event: MouseEvent, idField: string): void {
        if (this.multiple) {
            $event.stopPropagation();
        }
        this.toggleSelection(idField);
    }

    clickReset($event: MouseEvent): void {
        if (this.multiple) {
            $event.stopPropagation();
        }
        this.resetConfig();
    }

    toggleSelection(id: any): void {
        let value;
        if (this.multiple) {
            value = [... this.formCtrl.value];
            if (value?.includes(id)) {
                const index = value.indexOf(id);
                value.splice(index, 1);
            } else {
                value.push(id);
            }
        } else {
            value = id;
        }
        this.formCtrl.setValue(value, {emitEvent: true});
    }

    calculaAltura(): number {
        if (this.hideOptionField === '') {
            return Math.min(((this.elements.length * 40) + 16), this.alturaMenu);
        }
        const displayedElements = this.elements.filter(elm => !elm[this.hideOptionField]);
        return Math.min(((displayedElements.length * 40) + 16), this.alturaMenu);
    }

    showOption(element: any): boolean {
        if (this.hideOptionField === '') {
            return true;
        } else {
            return !element[this.hideOptionField];
        }
    }

    resetConfig(): any {
        this.resetConfigEmitter.emit();
    }
}
