<h1 matDialogTitle>{{data?.title ? data.title : 'Confirm'}}</h1>
<div mat-dialog-content>
    <section class="p-2" style="white-space: pre-line" *ngIf="data.message">{{data.message}}</section>
    <section class="p-2" *ngIf="!data.message"><b>Deseja confirmar a ação?</b></section>
</div>
<div mat-dialog-actions class="actions m-2 flex flex-row justify-start items-center content-center">
    <button mat-raised-button class="mr-2" color="primary" (click)="dialogRef.close(true)">
        {{data?.confirmLabel ? data.confirmLabel : 'Confirmar'}}
    </button>
    <button mat-button mat-dialog-close color="warn" *ngIf="showCancel" (click)="dialogRef.close(false)">{{data?.cancelLabel ? data.cancelLabel : 'Cancelar'}}</button>
</div>
