import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar modalidade indicador';
topicoCreate.descricao = 'Criar modalidade de indicador';
topicoCreate.path = 'admin/modalidade-indicador/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar modalidades indicador';
topicoList.descricao = 'Listar modalidades de indicador';
topicoList.path = 'admin/modalidade-indicador/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar modalidade indicador';
topicoEdit.descricao = 'Editar modalidade de indicador';
topicoEdit.path = 'admin/modalidade-indicador/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
