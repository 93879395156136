import {Type, Transform, Exclude} from 'class-transformer';
import {ProcessoJudicial, ModalidadeVinculacaoProcessoJudicial} from './';

export class VinculacaoProcessoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    numero: string;

    numeroFormatado: string;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial: ProcessoJudicial;

    @Type(() => ModalidadeVinculacaoProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeVinculacaoProcessoJudicial: ModalidadeVinculacaoProcessoJudicial;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.numero = null;
        this.numeroFormatado = null;
        this.processoJudicial = null;
        this.modalidadeVinculacaoProcessoJudicial = null;
    }
}
