import AndX from '@cdk/components/criteria/operators/and-x';
import Contains from '@cdk/components/criteria/operators/contains';
import EndsWith from '@cdk/components/criteria/operators/ends-with';
import Equal from '@cdk/components/criteria/operators/equal';
import GreaterThan from '@cdk/components/criteria/operators/greater-than';
import GreaterThanEqual from '@cdk/components/criteria/operators/greater-than-equal';
import LessThan from '@cdk/components/criteria/operators/less-than';
import LessThanEqual from '@cdk/components/criteria/operators/less-than-equal';
import NotContains from '@cdk/components/criteria/operators/not-contains';
import NotEndsWith from '@cdk/components/criteria/operators/not-ends-with';
import NotEqual from '@cdk/components/criteria/operators/not-equal';
import NotStartsWith from '@cdk/components/criteria/operators/not-starts-with';
import OrX from '@cdk/components/criteria/operators/or-x';
import StartsWith from '@cdk/components/criteria/operators/starts-with';
import In from '@cdk/components/criteria/operators/in';
import NotIn from '@cdk/components/criteria/operators/not-in';

export type Operators
    = Equal
    | NotEqual
    | LessThan
    | LessThanEqual
    | GreaterThan
    | GreaterThanEqual
    | Contains
    | NotContains
    | StartsWith
    | NotStartsWith
    | EndsWith
    | NotEndsWith
    | In
    | NotIn;

export const ExpressionPositionLeft = 'L';
export const ExpressionPositionRight = 'R';
export const ExpressionPositionMiddle = 'M';

export type ExpressionPositions
    = 'L'
    | 'R'
    | 'M';

export type Aggregators
    = AndX
    | OrX;

export const AggregatorsExpressionList: string[] = [
    'andX',
    'orX'
];
