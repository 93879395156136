export class Topico {

    titulo: string;
    descricao: string;
    path: string;
    module: any;

    constructor() {
        this.titulo = null;
        this.descricao = null;
        this.module = null;
        this.path = null;
    }
}
