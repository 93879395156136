import {Topico} from 'ajuda/topico';

const topicoCalculo = new Topico();
topicoCalculo.titulo = 'Calculo';
topicoCalculo.descricao = 'Módulo de Cálculo';
topicoCalculo.module = (): Promise<any> => import('./ajuda-calculo.module').then(m => m.AjudaCalculoModule);

const topicoCalculoCreate = new Topico();
topicoCalculoCreate.titulo = 'Criar calculo';
topicoCalculoCreate.descricao = 'Criar cálculo';
topicoCalculoCreate.path = 'administrativo/minhas-tarefas/[a-z-]+/tarefa/\\d+/calculo/editar/criar$';
topicoCalculoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoCalculoList = new Topico();
topicoCalculoList.titulo = 'Listar calculos';
topicoCalculoList.descricao = 'Listar cálculos';
topicoCalculoList.path = 'administrativo|calculo/minhas-tarefas/[a-z-]+/tarefa/\\d+/calculo/listar$';
topicoCalculoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoCalculoEdit = new Topico();
topicoCalculoEdit.titulo = 'Editar calculo';
topicoCalculoEdit.descricao = 'Editar cálculo';
topicoCalculoEdit.path = 'administrativo|calculo/minhas-tarefas/[a-z-]+/tarefa/\\d+/calculo/editar/\\d+$';
topicoCalculoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCalculo, topicoCalculoCreate, topicoCalculoList, topicoCalculoEdit ];
