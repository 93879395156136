<div *ngFor="let chat of chatList|sortByDate: 'atualizadoEm':'ASC':true" class="chat-list-item cursor-pointer"
     (click)="chatClick(chat)">
    <chat-imagem
        class="mat-badge"
        [imagem]="chat.capa"
        [mensagensNaoLidas]="chat.chatParticipante?.mensagensNaoLidas">
    </chat-imagem>
    <div class="chat-info">
        <span class="chat-nome">{{chat.nome}}</span>
        <span class="chat-mensagem">
            {{chat?.ultimaMensagem?.mensagem}}{{chat?.ultimaMensagem?.componenteDigital?.fileName}}
        </span>
    </div>
</div>
<div *ngIf="loading" class="loading">
    <mat-spinner color="primary" diameter="24"></mat-spinner>
</div>
