import {AbstractComparisonOperator} from '@cdk/components/criteria/operators/abstract-comparison-operator';
import {ExpressionPositionMiddle, ExpressionPositions} from '@cdk/components/criteria/operators/index';

export default class Equal extends AbstractComparisonOperator
{
    public readonly expression: string = 'eq:arg';

    constructor(
        literalOperator: string = 'é igual a',
        expressionPosition: ExpressionPositions = ExpressionPositionMiddle
    ) {
        super(
            literalOperator,
            expressionPosition
        );
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public checkExpression(expression: string): boolean {
        const regex: RegExp = new RegExp(/^eq:.*$/);
        return regex.test(expression);
    }

    /**
     * Returns expression value.
     * @param expression
     */
    public getValueFromExpression(expression: string): string {
        const regex: RegExp = new RegExp(/^eq:(.*)$/);
        const result = regex.exec(expression);
        if (result) {
            return result[1];
        }
        return null;
    }
}
