import {Exclude, Transform, Type} from 'class-transformer';
import {ComunicacaoJudicial} from './';
import {Documento} from '@cdk/models';

export class VinculacaoDocumentoComunicacaoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    documentoId: string;

    @Type(() => Documento)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    documento?: Documento;

    @Type(() => ComunicacaoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    comunicacaoJudicial: ComunicacaoJudicial;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.documentoId = null;
        this.documento = null;
        this.comunicacaoJudicial = null;
    }
}
