import * as moment from 'moment';
import {Type, Transform, Exclude} from 'class-transformer';
import {EspecieSetor, Folder, Pessoa, Setor, Tarefa, Usuario} from '@cdk/models';
import {
    MovimentoNacional,
    ModalidadeRepercussao,
    ProcessoJudicial,
    ModalidadeComunicacaoJudicial,
    AvisoComunicacaoJudicial,
    VinculacaoEtiquetaJudicial,
    VinculacaoDocumentoComunicacaoJudicial, VinculacaoProcessoJudicialProcesso, FonteDados
} from './';

export class ComunicacaoJudicial {

    '@id'?: string = null;
    '@type'?: string = null;
    '@context'?: string = null;

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    numero: string;

    @Exclude({toPlainOnly: true})
    numeroFormatado?: string;

    numeroAlternativo?: string;

    codigoLocalidade?: string;

    centenaDistribuicao: number;

    digitoDistribuicao: number;

    @Type(() => FonteDados)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    fonteDados?: FonteDados;

    postIt?: string;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial?: ProcessoJudicial;

    @Type(() => VinculacaoProcessoJudicialProcesso)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    vinculacaoProcessoJudicialProcesso?: VinculacaoProcessoJudicialProcesso;

    idComunicacao?: string;

    idConsultante?: string;

    poloEntidade?: string;

    teor?: string;

    nivelSigilo?: number;

    @Type(() => Folder)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    folder?: Folder;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraInicioPrazo?: moment.Moment;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraFinalPrazo?: moment.Moment;

    @Type(() => Pessoa)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    pessoa?: Pessoa;

    @Type(() => ModalidadeComunicacaoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeComunicacaoJudicial: ModalidadeComunicacaoJudicial;

    @Type(() => Tarefa)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    tarefa?: Tarefa;

    @Type(() => EspecieSetor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    especieSetor?: EspecieSetor;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    setor?: Setor;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    unidade: Setor;

    @Type(() => MovimentoNacional)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    movimentoNacional?: MovimentoNacional;

    @Type(() => ModalidadeRepercussao)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeRepercussao: ModalidadeRepercussao;

    status: number;

    statusIntegracao?: number;

    integracao: boolean;

    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    avisoComunicacaoJudicial?: AvisoComunicacaoJudicial;

    @Exclude({toPlainOnly: true})
    @Type(() => VinculacaoEtiquetaJudicial)
    vinculacoesEtiquetasJudiciais?: VinculacaoEtiquetaJudicial[];

    @Exclude({toPlainOnly: true})
    @Type(() => VinculacaoDocumentoComunicacaoJudicial)
    vinculacoesDocumentosComunicacoesJudiciais?: VinculacaoDocumentoComunicacaoJudicial[];

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    criadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    criadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    atualizadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    atualizadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    apagadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.numero = null;
        this.numeroFormatado = null;
        this.numeroAlternativo = null;
        this.codigoLocalidade = null;
        this.centenaDistribuicao = null;
        this.digitoDistribuicao = null;
        this.fonteDados = null;
        this.postIt = null;
        this.processoJudicial = null;
        this.vinculacaoProcessoJudicialProcesso = null;
        this.idComunicacao = null;
        this.idConsultante = null;
        this.poloEntidade = null;
        this.folder = null;
        this.teor = null;
        this.nivelSigilo = null;
        this.dataHoraInicioPrazo = null;
        this.dataHoraFinalPrazo = null;
        this.pessoa = null;
        this.modalidadeComunicacaoJudicial = null;
        this.tarefa = null;
        this.setor = null;
        this.unidade = null;
        this.movimentoNacional = null;
        this.modalidadeRepercussao = null;
        this.status = 0;
        this.statusIntegracao = null;
        this.integracao = false;
        this.avisoComunicacaoJudicial = null;
        this.vinculacoesEtiquetasJudiciais = [];
        this.vinculacoesDocumentosComunicacoesJudiciais = [];
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
