import {Exclude, Transform, Type} from 'class-transformer';
import {Pessoa} from '../../../../@cdk/models';

export class PessoaRepresentada {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    padrao?: string;

    @Type(() => Pessoa)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    pessoa?: Pessoa;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.padrao = null;
        this.pessoa = null;
    }
}






