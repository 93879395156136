import {ModuleWithProviders, NgModule} from '@angular/core';
import {ModuleExtension} from '../module.extension';
import CdkCriteriaFieldService from 'modules/judicial/@cdk/components/criteria/cdk-criteria-field.service';
import {TribunalService} from 'modules/judicial/@cdk/services/tribunal.service';

@NgModule()
export class JudicialExtensionModule implements ModuleExtension<JudicialExtensionModule> {
    forChild(path?: string): ModuleWithProviders<any> {
        let module = null;

        switch (path) {
            case 'app/main/apps/configuracoes/etiquetas/etiqueta-edit/regras-etiqueta/regra-etiqueta-edit':
            case 'app/main/apps/coordenador/etiquetas/etiqueta-edit/regras-etiqueta/regra-etiqueta-edit':
                module = [
                    {
                        provide: CdkCriteriaFieldService,
                        deps: [
                            TribunalService
                        ]
                    },
                    {
                        provide: TribunalService
                    }
                ];
                break;
        }

        return module;
    }

    forRoot(): ModuleWithProviders<JudicialExtensionModule> {
        return {
            ngModule : JudicialExtensionModule,
            providers: []
        };
    }

    getName(): string {
        return 'supp-judicial-frontend';
    }

    getVersion(): string {
        return '';
    }

    manageRoutes(path: string, routes?: any[]): any[] {
        return routes;
    }

    manageInterceptors(path: string, interceptors?: any[]): any[] {
        return interceptors;
    }

    manageMainMenu(mainMenu: any[]): any[] {
        return mainMenu;
    }

    manageRouterLinks(path: string, routerLinks?: any[]): string {
        return '';
    }

    manageComponents(path: string, components?: any[]): any[] {
        return components;
    }

    manageAjudas(path: string, ajudas?: any[]): any[] {
        return ajudas;
    }

    manageWidgets(path: string, widgets?: any[]): any[] {
        return widgets;
    }

    manageNotifications(path: string, notifications?: any[]): any[] {
        return notifications;
    }

    manageGeneroAfinity(path: string, model?:any): string {
        return '';
    }

    navigationConverter(menu: string): any {
        return null;
    }
}
