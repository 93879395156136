<mat-autocomplete (closed)="fechado()" [displayWith]="this.exibirUsername ? displayUsernameFn : displayUsuarioFn">
    <mat-option *ngFor="let usuario of usuarioList" [disabled]="(temDistribuidor && !usuario.isDisponivel)"
                [value]="usuario">
        <span [innerHTML]="displayUsuarioFn(usuario) | highlightOption: this.control.value"
              [ngStyle]="temDistribuidor && !usuario.isDisponivel ? {'text-decoration': 'line-through'} : {}"></span>
    </mat-option>
    <mat-option *ngIf="usuarioListIsLoading">
        <mat-spinner color="primary" diameter="16"></mat-spinner>
    </mat-option>
</mat-autocomplete>
