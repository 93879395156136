import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Criação de tribunais';
topico.descricao = 'Descrição detalhada da criação de tribunais';
topico.path = 'judicial';
topico.module = () =>
    import('modules/judicial/app/main/apps/admin/tribunal/tribunal-edit/dados-basicos/ajuda/ajuda-dados-basicos.module')
        .then(m => ({module: m.AjudaDadosBasicosModule, componentIndex: 0}));

export const topicosConfig =
    [
        topico
    ];
