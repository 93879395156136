<form name="form" [formGroup]="form">
    <div class="cdk-barra-pesquisa xs:hidden">
        <div class="cdk-barra-pesquisa-content">
            <div class="form-field-flex">
                <button mat-icon-button
                        class="custom-search-button"
                        aria-label="Pesquisar por"
                        [matTooltip]="'Pesquisando processos por ' + this.searchFieldName"
                        [matMenuTriggerFor]="menuBusca">
                    <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
                </button>
                <input class="input-field flex grow"
                       placeholder="Pesquisa rápida de processos por {{searchFieldName}}"
                       formControlName="processo"
                       [matAutocomplete]="processoSearch.autocomplete"
                       (blur)="checkProcesso()">
                <cdk-processo-search-autocomplete
                    #processoSearch="processoSearchAutocomplete"
                    [searchField]="searchField"
                    [control]="form.get('processo')"
                    [pagination]="processoPagination">
                </cdk-processo-search-autocomplete>
            </div>
        </div>
    </div>
    <mat-menu #menuBusca="matMenu" class="menu-busca" [overlapTrigger]="false" xPosition="before">
        <div class="p-3 m-1">
            <div class="custom-search-header">
                <span class="title">Buscar processos por:</span>
            </div>
            <div class="campo" (click)="selecionaCampo('NUP', 'NUP', 'administrativo')" [ngClass]="{'selected': searchField === 'NUP'}">
                <span>NUP</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]" *ngIf="searchField === 'NUP'">check</mat-icon>
            </div>
            <div class="campo" (click)="selecionaCampo('outroNumero', 'Outro Número', 'administrativo')" [ngClass]="{'selected': searchField === 'outroNumero'}">
                <span>Outro Número</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]" *ngIf="searchField === 'outroNumero'">check</mat-icon>
            </div>

            <ng-template #dynamicComponent></ng-template>
        </div>
    </mat-menu>
</form>
