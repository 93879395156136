import {Type, Transform, Exclude} from 'class-transformer';
import {Municipio, Usuario} from '@cdk/models';
import {ModalidadeComarca} from './modalidade-comarca.model';
import {Tribunal} from './tribunal.model';
import * as moment from 'moment';

export class Comarca {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    //@Exclude({ toPlainOnly: true })
    @Type(() => Municipio)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    sede: Municipio;

    //@Exclude({ toPlainOnly: true })
    @Type(() => Municipio)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    municipio: Municipio;

    ativo: boolean;

    //@Exclude({ toPlainOnly: true })
    @Type(() => Tribunal)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    tribunal: number;

    //@Exclude({ toPlainOnly: true })
    @Type(() => ModalidadeComarca)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeComarca: ModalidadeComarca;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    criadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    criadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    atualizadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    atualizadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    apagadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.sede = null;
        this.municipio = null;
        this.tribunal = null;
        this.ativo = null;
        this.modalidadeComarca = null;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
