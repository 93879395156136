<div class="header bg-primary text-on-primary-900">
    <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-folded lt-lg:hidden text-on-primary-900 font-weight-900"
                (click)="toggleSidebarLock()">
            <mat-icon class="text-on-primary-900 font-weight-900" color="primary">format_list_bulleted</mat-icon>
        </button>
        <button mat-icon-button class="toggle-sidebar-opened lg:hidden text-on-primary-900 font-weight-900"
                (click)="toggleSidebarOpen()">
            <mat-icon class="text-on-primary-900 font-weight-900" color="primary">arrow_forward</mat-icon>
        </button>
    </div>
    <div class="logo">
        <span class="logo-text">Últimas Operações</span>
    </div>
</div>

<div class="header">
    <mat-checkbox color="primary" class="ml-2 mb-1" (click)="toggleSelectAll($event)"
                  [checked]="!!selectedIds.length"
                  [indeterminate]="isIndeterminate">
    </mat-checkbox>

    <div class="toolbar-separator" *ngIf="selectedIds?.length"></div>

    <button mat-icon-button [matMenuTriggerFor]="moreMenu"
            aria-label="Ferramentas" (click)="$event.stopPropagation()"
            *ngIf="selectedIds?.length > 1">
        <mat-icon class="text-secondary">more_vert</mat-icon>
    </button>

    <mat-menu #moreMenu="matMenu">
        <button mat-menu-item aria-label="refazer selecionadas"
                (click)="doRefazerBloco()">
            <ng-container>
                <mat-icon>redo</mat-icon>
                <span>Refazer Selecionadas</span>
            </ng-container>
        </button>

        <button mat-menu-item aria-label="desfazer selecionadas"
                (click)="doDesfazerBloco()">
            <ng-container>
                <mat-icon>undo</mat-icon>
                <span>Desfazer Selecionadas</span>
            </ng-container>
        </button>
    </mat-menu>

    <div class="operacoes-list-control flex flex-row justify-start items-center content-cente">
        <div class="my-auto">
            <cdk-columns-select [formCtrl]="filtros"
                                [multiple]="false"
                                [tooltip]="'Campos'"
                                [elements]="opcoesFiltros"
                                [idField]="'valor'"
                                [labelField]="'label'"
                                [alturaMenu]="176"
                                [showReset]="false"></cdk-columns-select>
        </div>

        <div class="toolbar-separator"></div>

        <div *ngIf="!(loteAtual$ | async)">
            {{displayedOperacoes ? displayedOperacoes.length : 0}}/{{operacoes.length}}
        </div>

        <div *ngIf="(loteAtual$ | async)">
            {{displayedOperacoes ? displayedOperacoes.length : 0}}/{{operacoesLoteAtual.length}}
        </div>

    </div>
</div>

<mat-progress-bar *ngIf="operacoesPendentes > 0" mode="determinate"
                  [value]="(operacoesProcessando * 100) / operacoesPendentes"></mat-progress-bar>

<div class="content" cdkScrollbar  [cdkScrollbarOptions]="{suppressScrollX: false, suppressScrollY: false, wheelPropagation: false, updateOnRouteChange: true}">
    <div *ngIf="!(loteAtual$ | async)" class="operacoes">
        <mat-card appearance="outlined" class="mt-2 ml-2 mr-2" *ngFor="let operacao of displayedOperacoes">
            <mat-card-title>
                <div class="card-header">
                    <div class="check">
                        <mat-checkbox color="primary" class="pr-1 pb-1" [checked]="selectedIds.indexOf(operacao.id) !== -1"
                                      (change)="toggleInSelected(operacao.id)"
                                      (click)="$event.stopPropagation();">
                        </mat-checkbox>
                    </div>
                    <span class="text-secondary pre-wrap">{{operacao.type | titleCasePipe}}</span>
                    <span>
                        <button mat-icon-button
                                *ngFor="let quickPanelAction of getQuickPanelActions(operacao)"
                                (click)="quickPanelAction.action()"
                                aria-label="{{quickPanelAction.label | lowercase}}"
                                matTooltip="{{quickPanelAction.label}}">
                            <mat-icon>{{quickPanelAction.icon}}</mat-icon>
                        </button>
                        <span class="operacoe-status" *ngIf="operacao.status === 0"><mat-spinner color="primary" [diameter]="16"></mat-spinner></span>
                        <span class="operacoe-status" *ngIf="operacao.status === 1"><mat-icon>check</mat-icon></span>
                        <span class="operacoe-status" *ngIf="operacao.status === 2"><mat-icon class="warn">clear</mat-icon></span>
                        <span class="operacoe-status" *ngIf="operacao.status === 3"><mat-icon>cancel</mat-icon></span>
                    </span>
                </div>
            </mat-card-title>
            <mat-card-content>
                <div class="card-content">
                    <span class="text-secondary">{{operacao.content}}</span>
                    <span
                        class="mt-2 card-date text-secondary">{{operacao.dateTime?.toDate() | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div class="card-actions">
                    <div class="button" *ngIf="operacao.status >= 2 && operacao.redo">
                        <button mat-icon-button class="text-secondary" matTooltip="Refazer" (click)="refazer(operacao)">
                            <mat-icon>redo</mat-icon>
                        </button>
                    </div>
                    <div class="button" *ngIf="operacao.status === 1 && operacao.undo">
                        <button mat-icon-button class="text-secondary" matTooltip="Desfazer" (click)="desfazer(operacao)">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </div>
                    <div class="button" *ngIf="operacao.lote">
                        <button mat-icon-button class="text-secondary" matTooltip="Ver Lote" (click)="verLote(operacao.lote)">
                            <mat-icon>all_inbox</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <div *ngIf="(loteAtual$ | async)" class="operacoes">
        <mat-card appearance="outlined" class="mt-2 ml-2 mr-2">
            <mat-card-title>
                <div class="card-header">
                    <span class="text-secondary">Exibindo Lote: {{loteAtual.id}}</span>
                </div>
            </mat-card-title>
            <mat-card-actions>
                <div class="card-actions">
                    <div class="button">
                        <button mat-icon-button class="text-secondary" matTooltip="Voltar" aria-description="Voltar" (click)="verOperacoes()">
                            <mat-icon>arrow_left</mat-icon>
                        </button>
                    </div>
                    <div class="button" *ngIf="operacoesRefazerLoteAtual.length > 0">
                        <button mat-icon-button class="text-secondary" matTooltip="Refazer Lote" aria-description="Refazer Lote" (click)="refazerLoteAtual()">
                            <mat-icon>redo</mat-icon>
                        </button>
                    </div>
                    <div class="button" *ngIf="operacoesDesfazerLoteAtual.length > 0">
                        <button mat-icon-button class="text-secondary" matTooltip="Desfazer Lote" aria-description="Desfazer Lote" (click)="desfazerLoteAtual()">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>

        <mat-card appearance="outlined" class="mt-2 ml-2 mr-2" *ngFor="let operacao of displayedOperacoes">
            <mat-card-title>
                <div class="card-header">
                    <div class="check">
                        <mat-checkbox color="primary" class="pr-1 pb-1" [checked]="selectedIds.indexOf(operacao.id) !== -1"
                                      (change)="toggleInSelected(operacao.id)"
                                      (click)="$event.stopPropagation();">
                        </mat-checkbox>
                    </div>
                    <span class="text-secondary">{{operacao.type | titleCasePipe}}</span>
                    <span>
                        <button mat-icon-button
                                *ngFor="let quickPanelAction of getQuickPanelActions(operacao)"
                                (click)="quickPanelAction.action()"
                                aria-label="{{quickPanelAction.label | lowercase}}"
                                matTooltip="{{quickPanelAction.label}}">
                            <mat-icon>{{quickPanelAction.icon}}</mat-icon>
                        </button>
                        <span *ngIf="operacao.status === 0"><mat-spinner color="primary" [diameter]="16"></mat-spinner></span>
                        <span *ngIf="operacao.status === 1"><mat-icon>check</mat-icon></span>
                        <span *ngIf="operacao.status === 2"><mat-icon class="warn">clear</mat-icon></span>
                        <span *ngIf="operacao.status === 3"><mat-icon>cancel</mat-icon></span>
                    </span>
                </div>
            </mat-card-title>
            <mat-card-content>
                <div class="card-content">
                    <span class="text-secondary">
                        {{operacao.content}}
                    </span>
                    <span class="mt-2 card-date text-secondary">
                        {{operacao.dateTime?.toDate() | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </span>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div class="card-actions">
                    <div class="button" *ngIf="operacao.status >= 2 && operacao.redo">
                        <button mat-icon-button class="text-secondary" matTooltip="Refazer" aria-description="Refazer" (click)="refazer(operacao)">
                            <mat-icon>redo</mat-icon>
                        </button>
                    </div>
                    <div class="button" *ngIf="operacao.status === 1 && operacao.undo">
                        <button mat-icon-button class="text-secondary" matTooltip="Desfazer" aria-description="Desfazer" (click)="desfazer(operacao)">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
