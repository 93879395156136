import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {LoginService} from '../../../../../app/main/auth/login/login.service';

@Component({
    selector   : 'cdk-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class CdkNavHorizontalItemComponent implements OnInit
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;

    isGrantedRole: boolean;
    isCoordenador: boolean;

    /**
     *
     * @param _loginService
     */
    constructor(
        public _loginService: LoginService
    )
    {
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        let isGrantedRole = true;

        if (this.item.role) {
            isGrantedRole = false;
            const itemsRole = Array.isArray(this.item.role) ? this.item.role : [this.item.role];
            itemsRole.forEach((role) => {
                if (!isGrantedRole) {
                    isGrantedRole = this._loginService.isGranted(role);
                }
            });
        }

        let isGrantedRoleByFunction = true;
        if (this.item.hiddenByRole) {
            isGrantedRoleByFunction = !this.item.hiddenByRole(this._loginService.getUserProfile().roles);
        }
        this.isGrantedRole = isGrantedRole && isGrantedRoleByFunction;

        this.isCoordenador = this._loginService.isCoordenador();
    }
}
