import {Injectable} from '@angular/core';
import CriteriaFieldValueInterface from '@cdk/components/criteria/contracts/criteria-field-value.interface';
import CriteriaLoadDataResultInterface
    from '@cdk/components/criteria/contracts/criteria-load-data-result.interface';
import CriteriaFieldModel from '@cdk/components/criteria/model/criteria-field.model';
import {Operators} from '@cdk/components/criteria/operators';
import Contains from '@cdk/components/criteria/operators/contains';
import EndsWith from '@cdk/components/criteria/operators/ends-with';
import Equal from '@cdk/components/criteria/operators/equal';
import NotContains from '@cdk/components/criteria/operators/not-contains';
import NotEndsWith from '@cdk/components/criteria/operators/not-ends-with';
import NotEqual from '@cdk/components/criteria/operators/not-equal';
import NotStartsWith from '@cdk/components/criteria/operators/not-starts-with';
import StartsWith from '@cdk/components/criteria/operators/starts-with';
import {TribunalService} from 'modules/judicial/@cdk/services/tribunal.service';
import {Tribunal} from 'modules/judicial/@cdk/models';
import {toInteger} from 'lodash-es';
import {BehaviorSubject, from} from 'rxjs';
import {map} from 'rxjs/operators';
import CriteriaFieldInterface from '@cdk/components/criteria/contracts/criteria-field.interface';
import {CriteriaFieldProviderInterface} from '@cdk/components/criteria/contracts/criteria-field-provider.interface';

@Injectable()
export default class CdkCriteriaFieldService implements CriteriaFieldProviderInterface{

    private _criteriaFields: CriteriaFieldInterface[] = [];

    constructor(
        tribunalService: TribunalService
    ) {
        this._criteriaFields = [
            new CriteriaFieldModel(
                'observacao',
                'Observação Intimação',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                [
                    'INTIMAÇÃO',
                ],
                (operator: Operators) => from(import(
                    '@cdk/components/criteria/cdk-criteria-form/cdk-observacao/cdk-observacao-form.module'
                    ).then(m => m.CdkObservacaoFormModule)),
                (operator?: Operators) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => new BehaviorSubject<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }).asObservable(),
                10
            ),
            new CriteriaFieldModel(
                'tribunal.id',
                'Tribunal',
                [
                    new Equal(),
                    new NotEqual()
                ],
                [
                    'INTIMAÇÃO',
                ],
                (operator: Operators) => from(import(
                    'modules/judicial/@cdk/components/criteria/cdk-criteria-form/cdk-tribunal-processo/cdk-tribunal-processo-form.module'
                    ).then(m => m.CdkTribunalProcessoFormModule)),
                (operator?: Operators) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => tribunalService
                    .get(toInteger(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                    .pipe(map(
                        (tribunal: Tribunal) => {
                            return {
                                data: tribunal,
                                getValor: () => tribunal.id,
                                getExibicao: () => tribunal.nome
                            }
                        }
                    )),
                20
            ),
        ];
    }

    public getFields(): CriteriaFieldInterface[] {
        return this._criteriaFields;
    }
}
