import {Type, Transform, Exclude} from 'class-transformer';
import {ProcessoJudicial, MagistradoJudicial} from './';

export class VinculacaoMagistradoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Type(() => MagistradoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    magistradoJudicial: MagistradoJudicial;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial?: ProcessoJudicial;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.magistradoJudicial = null;
        this.processoJudicial = null;
    }
}
