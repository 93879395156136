import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Parametros calculo';
topico.descricao = 'Incluir parâmetros do cálculo';
topico.path = 'tarefa/\\d+/calculo/\\d+/elaborar$';
topico.module = (): Promise<any> => import('./ajuda-calculo-elaborar.module').then(m => m.AjudaCalculoElaborarModule);

export const topicosConfig =
    [
        topico
    ];
