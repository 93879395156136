import * as moment from 'moment';
import {Exclude, Transform, Type} from 'class-transformer';

import {ComponenteDigital, Juntada, OrigemDados, Usuario} from '@cdk/models';

export class Assinatura {

    @Exclude({ toPlainOnly: true })
    id?: number;

    @Exclude({ toPlainOnly: true })
    uuid?: string;

    algoritmoHash?: string;

    assinatura?: string;

    plainPassword?: string;

    assinadoPor?: string;

    cadeiaCertificadoPEM?: string;

    cadeiaCertificadoPkiPath?: string;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    dataHoraAssinatura?: moment.Moment;

    @Type(() => ComponenteDigital)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    componenteDigital?: ComponenteDigital;

    @Exclude({ toPlainOnly: true })
    @Type(() => OrigemDados)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    origemDados?: OrigemDados;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    criadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format() : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    criadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    atualizadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format() : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    atualizadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    apagadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format() : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    apagadoEm?: moment.Moment;

    @Type(() => ComponenteDigital)
    @Transform(value => value ? value.map(d => d.id) : null, { toPlainOnly: true })
    componentesDigitaisBloco?: ComponenteDigital[];

    constructor() {
        this.id = null;
        this.uuid = null;
        this.algoritmoHash = null;
        this.assinatura = null;
        this.assinadoPor = null;
        this.plainPassword = null;
        this.cadeiaCertificadoPEM = null;
        this.cadeiaCertificadoPkiPath = null;
        this.dataHoraAssinatura = null;
        this.componenteDigital = null;
        this.origemDados = null;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
        this.componentesDigitaisBloco = [];
    }
}
