import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Multa Causa';
topico.descricao = 'Parâmetros de cálculo multa causa';
topico.path = 'tarefa/\\d+/calculo/\\d+/elaborar/interessado/\\d+/[a-z]+/multa-causa$';
topico.module = (): Promise<any> => import('./ajuda-calculo-elaborar-multa-causa.module').then(m => m.AjudaCalculoElaborarMultaCausaModule);

export const topicosConfig =
    [
        topico
    ];
