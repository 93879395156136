import {NgModule} from '@angular/core';
import {
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule
} from '@cdk/angular/material';

import {CdkSharedModule} from '@cdk/shared.module';
import {CdkColumnsSelectComponent} from './cdk-columns-select.component';

@NgModule({
    declarations: [
        CdkColumnsSelectComponent
    ],
    imports: [
        CdkSharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
    ],
    exports: [
        CdkColumnsSelectComponent
    ]
})
export class CdkColumnsSelectModule {
}
