import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Listar interessados no calculo';
topico.descricao = 'Listar interessados no cálculo';
topico.path = 'tarefa/\\d+/calculo/\\d+/interessados/listar$';
topico.module = (): Promise<any> => import('./ajuda-calculo-interessados.module').then(m => m.AjudaCalculoInteressadosModule);

export const topicosConfig =
    [
        topico
    ];
