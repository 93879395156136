import {CdkConfig} from '@cdk/types';

/**
 * Default Cdk Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const cdkConfig: CdkConfig = {
    colorTheme: localStorage.getItem('colorTheme') ?? 'theme-default',
    scheme: localStorage.getItem('scheme') ?? 'light',
    screens: {
        'xs': '1px',
        'sm': '600px',
        'tablet': '770px',
        'md': '960px',
        'lg': '1280px',
        'xl': '1920px',
        'lt-sm':  '599px',
        'lt-tablet': '769px',
        'lt-md':  '959.9px',
        'lt-lg': '1279.9px',
        'lt-xl': '1919.9px',
        'gt-xs': '20px',
        'gt-sm': '599.9px',
        'gt-tablet': '769px',
        'gt-md': '959.9px',
        'gt-lg': '1279.9px',
        'gt-xl': '1919.9px'
    },
    themes: [
        {
            id: 'theme-default',
            name: 'Padrão',
            primary: '#4f46e5'
        },
        {
            id: 'theme-brand',
            name: 'Azul',
            primary: '#2196f3'
        },
        {
            id: 'theme-teal',
            name: 'Verde',
            primary: '#0d9488'
        },
        {
            id: 'theme-rose',
            name: 'Vermelho',
            primary: '#f43f5e'
        },
        {
            id: 'theme-purple',
            name: 'Roxo',
            primary: '#9333ea'
        },
        {
            id: 'theme-amber',
            name: 'Amarelo',
            primary: '#f59e0b'
        }
    ],
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'cdk-white-500',
            secondaryBackground: 'cdk-white-500',
            folded: true,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-1'
        },
        toolbar: {
            customBackgroundColor: false,
            background: 'cdk-white-500',
            hidden: false,
            position: 'below-static'
        },
        footer: {
            customBackgroundColor: true,
            background: 'cdk-white-500',
            hidden: true,
            position: 'below-fixed'
        },
        sidepanel: {
            hidden: true,
            position: 'right'
        }
    }
};
