import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar especie calculo';
topicoCreate.descricao = 'Criar espécie de cálculo';
topicoCreate.path = 'admin/genero-calculo/\\d+/especies-calculo/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar especies calculo';
topicoList.descricao = 'Listar espécies de cálculo';
topicoList.path = 'admin/genero-calculo/\\d+/especies-calculo/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar especie calculo';
topicoEdit.descricao = 'Editar espécie de cálculo';
topicoEdit.path = 'admin/genero-calculo/\\d+/especies-calculo/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
