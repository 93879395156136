import {Type, Transform, Exclude} from 'class-transformer';

export class MovimentoNacional {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    nome: string;

    situacao?: string;

    codigoNacional?: number;

    codigoNacionalPai?: number;

    movimento?: string;

    flgEletronico?: string;

    flgPapel?: string;

    presidenteVice?: string;

    colegiado?: string;

    monocratico?: string;

    visibilidadeExterna?: string;

    dispositivoLegal?: string;

    artigo?: string;

    glossario?: string;

    lft?: number;

    lvl?: number;

    rgt?: number;

    root?: number;

    @Type(() => MovimentoNacional)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    parent?: MovimentoNacional;

    justEs1Grau?: string;

    justEs2Grau?: string;

    justEsJuizadoEs?: string;

    justEsTurmas?: string;

    justEs1GrauMil?: string;

    justEs2GrauMil?: string;

    justEsJuizadoEsFp?: string;

    justTuEsUn?: string;

    justFed1Grau?: string;

    justFed2Grau?: string;

    justFedJuizadoEs?: string;

    justFedTurmas?: string;

    justFedNacional?: string;

    justFedRegional?: string;

    justTrab1Grau?: string;

    justTrab2Grau?: string;

    justTrabTst?: string;

    stf?: string;

    stj?: string;

    cjf?: string;

    cnj?: string;

    justMilUniao1Grau?: string;

    justMilUniaoStm?: string;

    justMilEst1Grau?: string;

    justMilEstTjm?: string;

    justElei1Grau?: string;

    justElei2Grau?: string;

    justEleiTse?: string;

    relevante: boolean;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.nome = null;
        this.situacao = null;
        this.codigoNacional = null;
        this.codigoNacionalPai = null;
        this.movimento = null;
        this.flgEletronico = null;
        this.flgPapel = null;
        this.presidenteVice = null;
        this.colegiado = null;
        this.monocratico = null;
        this.visibilidadeExterna = null;
        this.dispositivoLegal = null;
        this.artigo = null;
        this.glossario = null;
        this.lft = null;
        this.lvl = null;
        this.rgt = null;
        this.root = null;
        this.parent = null;
        this.justEs1Grau = null;
        this.justEs2Grau = null;
        this.justEsJuizadoEs = null;
        this.justEsTurmas = null;
        this.justEs1GrauMil = null;
        this.justEs2GrauMil = null;
        this.justEsJuizadoEsFp = null;
        this.justTuEsUn = null;
        this.justFed1Grau = null;
        this.justFed2Grau = null;
        this.justFedJuizadoEs = null;
        this.justFedTurmas = null;
        this.justFedNacional = null;
        this.justFedRegional = null;
        this.justTrab1Grau = null;
        this.justTrab2Grau = null;
        this.justTrabTst = null;
        this.stf = null;
        this.stj = null;
        this.cjf = null;
        this.cnj = null;
        this.justMilUniao1Grau = null;
        this.justMilUniaoStm = null;
        this.justMilEst1Grau = null;
        this.justMilEstTjm = null;
        this.justElei1Grau = null;
        this.justElei2Grau = null;
        this.justEleiTse = null;
        this.relevante = false;
    }
}
