import {Topico} from 'ajuda/topico';

const topico = new Topico();
topico.titulo = 'Multa Condenacao Honorarios';
topico.descricao = 'Parâmetros de cálculo multa sobre condenação e honorários';
topico.path = 'tarefa/\\d+/calculo/\\d+/elaborar/interessado/\\d+/[a-z]+/multa-condenacao-hono$';
topico.module = (): Promise<any> => import('./ajuda-calculo-elaborar-multa-condenacao-hono.module').then(m => m.AjudaCalculoElaborarMultaCondenacaoHonoModule);

export const topicosConfig =
    [
        topico
    ];
