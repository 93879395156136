import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CdkNavigationItem} from '@cdk/types';
import {CdkNavigationService} from '@cdk/components/navigation/navigation.service';
import {LoginService} from '../../../../../app/main/auth/login/login.service';

@Component({
    selector: 'cdk-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class CdkNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: CdkNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    isGrantedRole: boolean;
    isCoordenador: boolean;

    /**
     * Constructor
     */

    /**
     *
     * @param _changeDetectorRef
     * @param _cdkNavigationService
     * @param _loginService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _cdkNavigationService: CdkNavigationService,
        public _loginService: LoginService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._cdkNavigationService.onNavigationItemAdded,
            this._cdkNavigationService.onNavigationItemUpdated,
            this._cdkNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                const badge = this.item.badge;

                if (badge) {
                    delete this.item['badge'];
                    this._changeDetectorRef.detectChanges();
                    this.item['badge'] = badge;
                }

                const badgeCompartilhada = this.item.badgeCompartilhada;

                if (badgeCompartilhada) {
                    delete this.item['badgeCompartilhada'];
                    this._changeDetectorRef.detectChanges();
                    this.item['badgeCompartilhada'] = badgeCompartilhada;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        let isGrantedRole = true;

        if (this.item.role) {
            isGrantedRole = false;
            const itemsRole = Array.isArray(this.item.role) ? this.item.role : [this.item.role];
            itemsRole.forEach((role) => {
                if (!isGrantedRole) {
                    isGrantedRole = this._loginService.isGranted(role);
                }
            });
        }

        let isGrantedRoleByFunction = true;
        if (this.item.hiddenByRole) {
            isGrantedRoleByFunction = !this.item.hiddenByRole(this._loginService.getUserProfile().roles);
        }
        this.isGrantedRole = isGrantedRole && isGrantedRoleByFunction;

        this.isCoordenador = this._loginService.isCoordenador();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
