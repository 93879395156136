function extractJWTPayload<T>(jwt: string): T {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
}
export function getParams(coraToken?: string): {coraToken: string, idPessoa: string, lifeTime: number} {
    if (!coraToken) {
        throw new Error('SEM PORTAL TOKEN')
    }

    const payload = extractJWTPayload<{idPessoa?:string, exp?: number}>(coraToken);

    if (!payload.idPessoa) {
        throw new Error('PORTAL TOKEN ESTA SEM PROPRIEDADE idPessoa')
    }

    if (!payload.exp) {
        throw new Error('PORTAL TOKEN ESTA SEM PROPRIEDADE exp')
    }

    const lifeTime = payload.exp - (Date.now() / 1000);

    const { idPessoa } = payload;
    return {coraToken, idPessoa, lifeTime};
}
