import * as moment from 'moment';
import {Type, Transform, Exclude} from 'class-transformer';
import {Processo, Setor, Usuario} from '@cdk/models';
import {Tribunal} from './tribunal.model';
import {OrgaoJulgador} from './orgao-julgador.model';
import {VinculacaoProcessoJudicialProcesso} from './vinculacao-processo-judicial-processo.model';
import {VinculacaoProcessoJudicial} from './vinculacao-processo-judicial.model';
import {ClasseNacional} from './classe-nacional.model';
import {FonteDados} from './fonte-dados.model';
import {ModalidadeRisco} from './modalidade-risco.model';
import {PessoaRepresentada} from './pessoa-representada.model';

export class ProcessoJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    numero?: string;

    @Exclude({toPlainOnly: true})
    numeroFormatado?: string;

    numeroAlternativo?: string;

    numeroAlternativoAscii?: string;

    @Type(() => FonteDados)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    fonteDados?: FonteDados;

    @Type(() => ClasseNacional)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    classeNacional?: ClasseNacional;

    @Exclude()
    tribunal?: Tribunal;

    @Type(() => OrgaoJulgador)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    orgaoJulgador?: OrgaoJulgador;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraAjuizamento?: moment.Moment;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraCitacao?: moment.Moment;

    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    dataHoraTransitoJulgado?: moment.Moment;

    competencia?: number;

    codigoOrgaoJulgador?: string;

    codigoLocalidade?: string;

    nivelSigilo?: number;

    intervencaoMP?: boolean;

    AJG?: boolean;

    eletronico?: boolean;

    aceitaNumeroInvalido?: boolean;

    tamanhoProcesso?: number;

    valorCausa?: number;

    @Type(() => PessoaRepresentada)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    pessoaRepresentada?: PessoaRepresentada;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    setorAtual: Setor;

    @Type(() => ModalidadeRisco)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    modalidadeRisco?: ModalidadeRisco;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    criadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    criadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    atualizadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    atualizadoEm?: moment.Moment;

    @Exclude({ toPlainOnly: true })
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    apagadoPor?: Usuario;

    @Exclude({ toPlainOnly: true })
    @Transform(value => value ? value.format('YYYY-MM-DDTHH:mm:ss') : null, { toPlainOnly: true })
    @Transform(value => value ? moment(value) : null, { toClassOnly: true })
    apagadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => VinculacaoProcessoJudicialProcesso)
    vinculacoesProcessosJudiciaisProcessos: VinculacaoProcessoJudicialProcesso[];

    @Exclude({ toClassOnly: true })
    @Type(() => Processo)
    @Transform(value => value ? value.id : null, { toPlainOnly: true })
    processoConversao?: Processo;

    @Exclude({toPlainOnly: true})
    @Type(() => VinculacaoProcessoJudicial)
    vinculacoesProcessosJudiciais: VinculacaoProcessoJudicial[];

    constructor() {
        this.id = null;
        this.uuid = null;
        this.numero = null;
        this.numeroFormatado = null;
        this.numeroAlternativo = null;
        this.numeroAlternativoAscii = null;
        this.fonteDados = null;
        this.classeNacional = null;
        this.orgaoJulgador = null;
        this.dataHoraAjuizamento = null;
        this.dataHoraCitacao = null;
        this.dataHoraTransitoJulgado = null;
        this.competencia = null;
        this.pessoaRepresentada = null;
        this.codigoOrgaoJulgador = null;
        this.codigoLocalidade = null;
        this.nivelSigilo = null;
        this.intervencaoMP = false;
        this.AJG = false;
        this.eletronico = false;
        this.aceitaNumeroInvalido = false;
        this.tamanhoProcesso = null;
        this.valorCausa = null;
        this.setorAtual = null;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
        this.vinculacoesProcessosJudiciaisProcessos = [];
        this.processoConversao = null;
        this.vinculacoesProcessosJudiciais = [];
    }
}
