import {RoleGuard} from '../../app/main/apps/role.guard';

// genero calculo
import {topicosConfig as calculoAdminGeneroCalculo} from './app/main/apps/admin/genero-calculo/ajuda/topicos-config';
import {topicosConfig as calculoAdminGeneroCalculoEspecieCalculo} from './app/main/apps/admin/genero-calculo/especie-calculo/ajuda/topicos-config';
import {topicosConfig as calculoAdminGeneroCalculoEspecieCalculoVinculacaoEspecieCalculoTipoParametroCalculo}
    from './app/main/apps/admin/genero-calculo/especie-calculo/vinculacao-especie-calculo-tipo-parametro-calculo/ajuda/topicos-config';

// modalidade elaboracao calculo
import {topicosConfig as calculoAdminModalidadeElaboracaoCalculo} from './app/main/apps/admin/modalidade-elaboracao-calculo/ajuda/topicos-config';

// modalidade indicador
import {topicosConfig as calculoAdminModalidadeIndicador} from './app/main/apps/admin/modalidade-indicador/ajuda/topicos-config';

// modalidade integracao tipo indicador
import {topicosConfig as calculoAdminModalidadeModalidadeIntegracaoTipoIndicador} from './app/main/apps/admin/modalidade-integracao-tipo-indicador/ajuda/topicos-config';

// modalidade parametro calculo
import {topicosConfig as calculoAdminModalidadeModalidadeParametroCalculo} from './app/main/apps/admin/modalidade-parametro-calculo/ajuda/topicos-config';

// modalidade preenchimento parametro calculo
import {topicosConfig as calculoAdminModalidadeModalidadePreenchimentoParametroCalculo} from './app/main/apps/admin/modalidade-preenchimento-parametro-calculo/ajuda/topicos-config';

// modalidade status calculo
import {topicosConfig as calculoAdminModalidadeModalidadeStatusCalculo} from './app/main/apps/admin/modalidade-status-calculo/ajuda/topicos-config';

// moeda
import {topicosConfig as calculoAdminMoeda} from './app/main/apps/admin/moeda/ajuda/topicos-config';

// tipo indicador
import {topicosConfig as calculoAdminTipoIndicador} from './app/main/apps/admin/tipo-indicador/ajuda/topicos-config';
import {topicosConfig as calculoAdminTipoIndicadorConfiguracaoTipoIndicador} from './app/main/apps/admin/tipo-indicador/configuracao-tipo-indicador/ajuda/topicos-config';
import {topicosConfig as calculoAdminTipoIndicadorIndicador} from './app/main/apps/admin/tipo-indicador/indicador/ajuda/topicos-config';
import {topicosConfig as calculoAdminTipoIndicadorVinculacaoTipoIndicador} from './app/main/apps/admin/tipo-indicador/vinculacao-tipo-indicador/ajuda/topicos-config';

// tipo parametro calculo
import {topicosConfig as calculoAdminTipoParametroCalculo} from './app/main/apps/admin/tipo-parametro-calculo/ajuda/topicos-config';
import {topicosConfig as calculoAdminTipoParametroCalculoParametroCalculo} from './app/main/apps/admin/tipo-parametro-calculo/parametro-calculo/ajuda/topicos-config';

// Tarefa Detail
// Calculo
import {topicosConfig as calculoDetail} from './app/main/apps/calculo/ajuda/topicos-config';
// Listar Interessados
import {topicosConfig as calculoDetailInteressadoList} from './app/main/apps/calculo/interessado-calculo-list/ajuda/topicos-config';
// Anexar Documento
import {topicosConfig as calculoDetailAnexarDocumento} from './app/main/apps/calculo/calculo-documento-anexo/ajuda/topicos-config';
// Elaborar Calculo
import {topicosConfig as calculoDetailElaborarCalculo} from './app/main/apps/calculo/calculo-elaborar/ajuda/topicos-config';
import {topicosConfig as AjudaCalculoElaborarCustasParcelas} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/custas-parcelas/topicos-config';
import {topicosConfig as AjudaCalculoElaborarHonorariosCausa} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/custas-parcelas/topicos-config';
import {topicosConfig as AjudaCalculoElaborarHonorariosCondenacao} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/honorarios-condenacao/topicos-config';
import {topicosConfig as AjudaCalculoElaborarHonorariosParcelas} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/honorarios-parcelas/topicos-config';
import {topicosConfig as AjudaCalculoElaborarJurosCorrecao} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/juros-correcao/topicos-config';
import {topicosConfig as AjudaCalculoElaborarMultaCausa} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/multa-causa/topicos-config';
import {topicosConfig as AjudaCalculoElaborarMultaCondenacao} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/multa-condenacao/topicos-config';
import {topicosConfig as AjudaCalculoElaborarMultaCondenacaoHono} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/multa-condenacao-hono/topicos-config';
import {topicosConfig as AjudaCalculoElaborarMultaParcelas} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/multa-parcelas/topicos-config';
import {topicosConfig as AjudaCalculoElaborarPrincipalParcelas} from './app/main/apps/calculo/calculo-elaborar/parametro-calculo/ajuda/principal-parcelas/topicos-config';
import * as models from "../../@cdk/models";

export const modulesConfigCalculo: any =
    {
        name: 'calculo',
        label: 'Cálculo',
        icon: 'keyboard',
        generoAffinity: {},
        navigationConverter: {
            mainMenu: []
        },
        routes: {
            'app/main/apps/tarefas/tarefa-detail': [
                {
                    path: 'calculo',
                    loadChildren: ()  => import(
                        'modules/calculo/app/main/apps/calculo/calculo.module'
                        ).then(m => m.CalculoModule),
                }
            ],
            'app/main/apps/admin': [
                {
                    path: 'moeda',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/moeda/moeda.module'
                        ).then(m => m.MoedaModule)
                },
                {
                    path: 'modalidade-indicador',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-indicador/modalidade-indicador.module'
                        ).then(m => m.ModalidadeIndicadorModule)
                },
                {
                    path: 'modalidade-status-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-status-calculo/modalidade-status-calculo.module'
                        ).then(m => m.ModalidadeStatusCalculoModule)
                },
                {
                    path: 'modalidade-indicador-por-periodo-vigencia',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-indicador-por-periodo-vigencia/modalidade-indicador-por-periodo-vigencia.module'
                        ).then(m => m.ModalidadeIndicadorPorPeriodoVigenciaModule)
                },
                {
                    path: 'modalidade-elaboracao-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-elaboracao-calculo/modalidade-elaboracao-calculo.module'
                        ).then(m => m.ModalidadeElaboracaoCalculoModule)
                },
                {
                    path: 'modalidade-parametro-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-parametro-calculo/modalidade-parametro-calculo.module'
                        ).then(m => m.ModalidadeParametroCalculoModule)
                },
                {
                    path: 'modalidade-preenchimento-parametro-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-preenchimento-parametro-calculo/modalidade-preenchimento-parametro-calculo.module'
                        ).then(m => m.ModalidadePreenchimentoParametroCalculoModule)
                },
                {
                    path: 'modalidade-integracao-tipo-indicador',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/modalidade-integracao-tipo-indicador/modalidade-integracao-tipo-indicador.module'
                        ).then(m => m.ModalidadeIntegracaoTipoIndicadorModule)
                },
                {
                    path: 'tipos-parametros-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/tipo-parametro-calculo/tipo-parametro-calculo.module'
                        ).then(m => m.TipoParametroCalculoModule)
                },
                {
                    path: 'valor-referencia-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/valor-referencia/valor-referencia.module'
                        ).then(m => m.ValorReferenciaModule)
                },
				{
					path: 'tipo-indicador',
					loadChildren: () => import(
						'modules/calculo/app/main/apps/admin/tipo-indicador/tipo-indicador.module'
						).then(m => m.TipoIndicadorModule),
					canActivate: [RoleGuard],
					data: {roles: ['ROLE_CALCULO_ADMIN']}
				},
                {
                    path: 'genero-calculo',
                    loadChildren: () => import(
                        'modules/calculo/app/main/apps/admin/genero-calculo/genero-calculo.module'
                        ).then(m => m.GeneroCalculoModule),
                    canActivate: [RoleGuard],
                    data: {roles: ['ROLE_CALCULO_ADMIN']}
                }
			]
		},
		mainMenu: [
            {
                id: 'tarefas',
                entries: [
                    {
                        id: 'calculo',
                        title: 'Cálculo',
                        type: 'item',
                        icon: 'keyboard',
                        url: '/apps/tarefas/calculo/minhas-tarefas/entrada',
                        badge: {
                            title: '0',
                            bg: '#F44336',
                            fg: '#FFFFFF'
                        },
                        role: 'ROLE_COLABORADOR'
                    }
                ],
                role: 'ROLE_COLABORADOR'
            }
		],
		routerLinks: {},
		sidebars: {
			'app/main/apps/admin/sidebars/main': [
				{
					nome: 'Moedas',
					icon: 'attach_money',
					link: 'moeda',
					role: 'ROLE_CALCULO_ADMIN'
				},
                {
                    nome: 'Mod. Indicador',
                    icon: 'attach_money',
                    link: 'modalidade-indicador',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Ind. Por Período Vigência',
                    icon: 'list',
                    link: 'modalidade-indicador-por-periodo-vigencia',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Integração',
                    icon: 'attach_money',
                    link: 'modalidade-integracao-tipo-indicador',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Status Calculo',
                    icon: 'linear_scale',
                    link: 'modalidade-status-calculo',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Elaboracao Calculo',
                    icon: 'perm_data_setting',
                    link: 'modalidade-elaboracao-calculo',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Param. Calculo',
                    icon: 'list',
                    link: 'modalidade-parametro-calculo',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Mod. Preen. Param. Calculo',
                    icon: 'list',
                    link: 'modalidade-preenchimento-parametro-calculo',
                    role: 'ROLE_CALCULO_ROOT'
                },
                {
                    nome: 'Tipos Param. Cálculo',
                    icon: 'functions',
                    link: 'tipos-parametros-calculo',
                    role: 'ROLE_CALCULO_ADMIN'
                },
                {
                    nome: 'Valores de Referência',
                    icon: 'price_check',
                    link: 'valor-referencia-calculo',
                    role: 'ROLE_CALCULO_ADMIN'
                },
                {
                    nome: 'Tipos de Indicador',
                    icon: 'timeline',
                    link: 'tipo-indicador',
                    role: 'ROLE_CALCULO_ADMIN'
                },
                {
                    nome: 'Gêneros de Cálculo',
                    icon: 'keyboard',
                    link: 'genero-calculo',
                    role: 'ROLE_CALCULO_ADMIN'
                }
            ]
        },
        components: {
            'app/main/apps/tarefas': [
                (): Promise<any> => import(
                    './app/main/apps/tarefas/tarefa-list-item-calculo/tarefa-list-item-calculo.module'
                    ).then(m => m.TarefaListItemCalculoModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/default': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/default/default.module'
                    ).then(m => m.DefaultModule),
            ],

            //ADICIONAL
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/adicional': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/adicional/adicional.module'
                    ).then(m => m.AdicionalModule),
            ],

            //PRINCIPAL
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-parcelas': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/principal/principal-parcelas/principal-parcelas.module'
                    ).then(m => m.PrincipalParcelasModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-lp': [
                (): Promise<any> => import(
                    './@cdk/components/tipo-parametro-calculo/components/principal/principal-licenca-premio/principal-licenca-premio.module'
                    ).then(m => m.PrincipalLicencaPremioModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-13': [
                (): Promise<any> => import(
                    './@cdk/components/tipo-parametro-calculo/components/principal/principal-13-salario/principal-13-salario.module'
                    ).then(m => m.Principal13SalarioModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-fpap': [
                (): Promise<any> => import(
                    './@cdk/components/tipo-parametro-calculo/components/principal/principal-abono-permanencia/principal-abono-permanencia.module'
                    ).then(m => m.PrincipalAbonoPermanenciaModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-hep': [
                (): Promise<any> => import(
                    './@cdk/components/tipo-parametro-calculo/components/principal/principal-hora-extra/principal-hora-extra.module'
                    ).then(m => m.PrincipalHoraExtraModule),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-17597': [
                (): Promise<any> => import(
                    './@cdk/components/tipo-parametro-calculo/components/principal/principal-data-base-17597/principal-data-base17597.module'
                    ).then(m => m.PrincipalDataBase17597Module),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-ac4': [
                (): Promise<any> => import(
                    '././@cdk/components/tipo-parametro-calculo/components/principal/principal-ac4/principal-ac4.module'
                    ).then(m => m.PrincipalAc4Module),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-17098': [
                (): Promise<any> => import(
                    '././@cdk/components/tipo-parametro-calculo/components/principal/principal-lei-17098/principal-lei17098.module'
                    ).then(m => m.PrincipalLei17098Module),
            ],

            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/principal-servidor-18474': [
                (): Promise<any> => import(
                    '././@cdk/components/tipo-parametro-calculo/components/principal/principal-lei-18474/principal-lei18474.module'
                    ).then(m => m.PrincipalLei18474Module),
            ],

            //HONORARIOS
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/honorarios-0': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/comum/parametro-0/parametro-0.module'
                    ).then(m => m.Parametro0Module),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/honorarios-parcelas': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/honorarios/honorarios-parcelas/honorarios-parcelas.module'
                    ).then(m => m.HonorariosParcelasModule),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/honorarios-causa': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/honorarios/honorarios-causa/honorarios-causa.module'
                    ).then(m => m.HonorariosCausaModule),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/honorarios-condenacao': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/honorarios/honorarios-condenacao/honorarios-condenacao.module'
                    ).then(m => m.HonorariosCondenacaoModule),
            ],

            //JUROS-CORRECAO
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/juros-correcao': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/juros-correcao/juros-correcao/juros-correcao.module'
                    ).then(m => m.JurosCorrecaoModule),
            ],

            //MULTA
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/multa-0': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/comum/parametro-0/parametro-0.module'
                    ).then(m => m.Parametro0Module),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/multa-causa': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/multa/multa-causa/multa-causa.module'
                    ).then(m => m.MultaCausaModule),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/multa-condenacao': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/multa/multa-condenacao/multa-condenacao.module'
                    ).then(m => m.MultaCondenacaoModule),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/multa-parcelas': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/multa/multa-parcelas/multa-parcelas.module'
                    ).then(m => m.MultaParcelasModule),
            ],
            //CALCULO ADICIONAL
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/calc-adicionais-parcelas': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/calculo-adicionais-parcelas/calculo-adicionais-parcelas.module'
                    ).then(m => m.CalculoAdicionaisParcelasModule),
            ],
            'app/main/apps/calculo/calculo-elaborar/parametro-calculo/calc-adicionais-0': [
                (): Promise<any> => import(
                    './app/main/apps/calculo/calculo-elaborar/parametro-calculo/components/comum/parametro-0/parametro-0.module'
                    ).then(m => m.Parametro0Module),
            ],
        },
        ajuda: [
            ...calculoAdminGeneroCalculo,
            ...calculoAdminGeneroCalculoEspecieCalculo,
            ...calculoAdminGeneroCalculoEspecieCalculoVinculacaoEspecieCalculoTipoParametroCalculo,
            ...calculoAdminModalidadeElaboracaoCalculo,
            ...calculoAdminModalidadeIndicador,
            ...calculoAdminModalidadeModalidadeIntegracaoTipoIndicador,
            ...calculoAdminModalidadeModalidadeParametroCalculo,
            ...calculoAdminModalidadeModalidadePreenchimentoParametroCalculo,
            ...calculoAdminModalidadeModalidadeStatusCalculo,
            ...calculoAdminMoeda,
            ...calculoAdminTipoIndicador,
            ...calculoAdminTipoIndicadorConfiguracaoTipoIndicador,
            ...calculoAdminTipoIndicadorIndicador,
            ...calculoAdminTipoIndicadorVinculacaoTipoIndicador,
            ...calculoAdminTipoParametroCalculo,
            ...calculoAdminTipoParametroCalculoParametroCalculo,
            ...calculoDetail,
            ...calculoDetailInteressadoList,
            ...calculoDetailAnexarDocumento,
            ...calculoDetailElaborarCalculo,
            ...AjudaCalculoElaborarCustasParcelas,
            ...AjudaCalculoElaborarHonorariosCausa,
            ...AjudaCalculoElaborarHonorariosCondenacao,
            ...AjudaCalculoElaborarHonorariosParcelas,
            ...AjudaCalculoElaborarJurosCorrecao,
            ...AjudaCalculoElaborarMultaCausa,
            ...AjudaCalculoElaborarMultaCondenacao,
            ...AjudaCalculoElaborarMultaCondenacaoHono,
            ...AjudaCalculoElaborarMultaParcelas,
            ...AjudaCalculoElaborarPrincipalParcelas
        ],
        widgets: [],
    };
