import {Type} from '@angular/core';
import CriteriaFieldValueInterface from '@cdk/components/criteria/contracts/criteria-field-value.interface';
import CriteriaFieldInterface from '@cdk/components/criteria/contracts/criteria-field.interface';
import CriteriaLoadDataResultInterface
    from '@cdk/components/criteria/contracts/criteria-load-data-result.interface';
import {Operators} from '@cdk/components/criteria/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export default class CriteriaFieldModel implements CriteriaFieldInterface
{
    constructor(
        public readonly field: string,
        public readonly fieldName: string,
        public readonly operators: Operators[],
        public readonly modalidades: string[],
        public readonly componentModule: (operator?: Operators) => Observable<Type<any>>,
        public readonly componentInputs: (operator?: Operators) => Object,
        protected readonly _loadData: (criteriaFieldValue: CriteriaFieldValueInterface) => Observable<CriteriaLoadDataResultInterface>,
        public readonly ordem: number
    ) {
    }

    public loadData (criteriaFieldValue: CriteriaFieldValueInterface): Observable<CriteriaLoadDataResultInterface> {
        if (criteriaFieldValue.data) {
             return new BehaviorSubject<CriteriaLoadDataResultInterface>(criteriaFieldValue.data)
                 .asObservable();
        }
        return this._loadData(criteriaFieldValue)
            .pipe(
                tap((data) => criteriaFieldValue.data = data)
            );
    }
}
