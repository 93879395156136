<ng-container *ngIf="!item.hidden && (isGrantedRole && (!item.isCoordenador || (item.isCoordenador && isCoordenador)))">

    <div class="group-title" [ngClass]="item.classes">
        <span class="hint-text" [translate]="item.translate">{{ item.title }}</span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <cdk-nav-vertical-group *ngIf="item.type==='group'" [item]="item"></cdk-nav-vertical-group>
            <cdk-nav-vertical-collapsable *ngIf="item.type==='collapsable'"
                                           [item]="item"></cdk-nav-vertical-collapsable>
            <cdk-nav-vertical-item *ngIf="item.type==='item'" [item]="item"></cdk-nav-vertical-item>
        </ng-container>
    </div>

</ng-container>
