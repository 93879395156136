import {Exclude} from 'class-transformer';

export class ModalidadeVinculacaoProcessoJudicial {
    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    valor?: string;

    descricao?: string;

    ativo: boolean;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.valor = null;
        this.descricao = null;
        this.ativo = true;
    }
}
