<mat-error *ngIf="errorMessage" class="chat-mensagem-error">
    {{errorMessage}}
</mat-error>

<div *ngIf="loading" class="loading">
    <mat-spinner color="primary" diameter="24"></mat-spinner>
</div>

<div *ngFor="let chatMensagem of chatMensagens | sortByDate: 'criadoEm':'DESC':true; let i = index" class="message-row"
     [ngClass]="{
                    'me': chatMensagem.usuario.id === usuarioLogado.id,
                    'contact': chatMensagem.usuario.id !== usuarioLogado.id,
                    'first-of-group': isFirstMessageOfGroup(chatMensagem, i),
                    'last-of-group': isLastMessageOfGroup(chatMensagem, i)
                }">
    <chat-imagem *ngIf="shouldShowContactAvatar(chatMensagem, i)"
        [imagem]="chatMensagem.usuario.imgPerfil">
    </chat-imagem>

    <div class="bubble">
        <div class="message">{{chatMensagem.mensagem}}{{chatMensagem?.componenteDigital?.fileName}}</div>
    </div>
    <div class="time text-secondary">
        <span *ngIf="chatMensagem.id"> {{chatMensagem.criadoEm | date:'short'}} </span>
        <span *ngIf="saving === true && !chatMensagem.id">Enviando...</span>
        &nbsp;
    </div>

</div>
