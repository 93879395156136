export abstract class AbstractOperator
{
    protected abstract readonly expression: string;

    /**
     * Returns expression.
     */
    public getExpression(): string {
        return this.expression
    }

    /**
     * Returns expression literal description.
     * @param args
     */
    public abstract getLiteralExpression(...args: any[]): string;
}
