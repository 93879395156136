<button mat-icon-button
        class="mat-elevation-z1"
        [matMenuTriggerFor]="colorMenu"
        (menuOpened)="onMenuOpen()"
        [ngClass]="selectedPalette + '-' + selectedHue">
    <mat-icon>palette</mat-icon>
</button>

<mat-menu #colorMenu="matMenu" class="cdk-material-color-picker-menu mat-elevation-z8">

    <header [ngClass]="selectedColor?.class || 'mat-accent'" class="mat-elevation-z4 flex flex-row justify-between items-center content-center">

        <button mat-icon-button
                class="text-secondary"
                [style.visibility]="view === 'hues' ? 'visible' : 'hidden'"
                (click)="goToPalettesView($event)" aria-label="Palette">
            <mat-icon class="h-20 text-2xl leading-none">arrow_back</mat-icon>
        </button>

        <span *ngIf="selectedColor?.palette">
            {{selectedColor.palette}} {{selectedColor.hue}}
        </span>

        <span *ngIf="!selectedColor?.palette">
            Select a Color
        </span>

        <button mat-icon-button
                class="remove-color-button text-secondary"
                (click)="removeColor($event)"
                aria-label="Remove color"
                matTooltip="Remove color">
            <mat-icon class="h-20 text-2xl leading-none">delete</mat-icon>
        </button>
    </header>

    <div [ngSwitch]="view" class="views">

        <div class="view" *ngSwitchCase="'palettes'">

            <div class="flex-wrap flex-row justity-start items-start content-start colors" cdkScrollbar  [cdkScrollbarOptions]="{suppressScrollX: false, suppressScrollY: false, wheelPropagation: false, updateOnRouteChange: true}">
                <div class="color flex flex-row justify-center items-center content-center"
                     *ngFor="let color of colors"
                     [ngClass]="color.key"
                     [class.selected]="selectedPalette === color.key"
                     (click)="selectPalette($event, color.key)">
                </div>
            </div>
        </div>

        <div class="view" *ngSwitchCase="'hues'">
            <div class="colors flex-wrap flex-row justify-start items-start content-start" cdkScrollbar  [cdkScrollbarOptions]="{suppressScrollX: false, suppressScrollY: false, wheelPropagation: false, updateOnRouteChange: true}">
                <div class="color flex flex-row justify-center items-center content-center"
                     *ngFor="let hue of hues"
                     [ngClass]="{
                        'hidden' : selectedPalette === 'cdk-white' && hue !== '500' || selectedPalette === 'cdk-black' && hue !== '500',
                        '{{selectedPalette}}-{{hue}}' : true
                     }"
                     [class.selected]="selectedHue === hue"
                     (click)="selectHue($event, hue)">
                </div>
            </div>
        </div>

    </div>
</mat-menu>
