<div class="header mat-elevation-z4 flex flex-row justify-between items-center content-center bg-primary" tabindex="-1">

    <ng-container>
        <div class="title ml-2 cursor-pointer flex flex-row justify-start items-center content-center"
             (click)="closeSidebar()">
            <mat-icon class="h-32 w-32 text-4xl leading-none text-on-primary">chat</mat-icon>
            <h3 class="ml-3 text-on-primary">Mensagens</h3>
        </div>
    </ng-container>

    <button mat-icon-button class="toggle-sidebar-open mr-2 text-on-primary" (click)="closeSidebar()">
        <mat-icon class="text-on-primary">close</mat-icon>
    </button>

</div>
<div class="content-card" tabindex="-1"
     *ngIf="usuarioLogado">

    <mat-sidenav-container>
        <!-- LEFT SIDENAV-->
        <mat-sidenav class="sidenav sidenav-left" position="start" opened="true" mode="side" tabindex="-1"
                     [disableClose]="true"
                     *ngIf="activeCard === 'chat-list'">
            <chat-header class="chat-header"
                (pesquisaChatHandler)="pesquisaChat($event)"
                (criarGrupoHandler)="criarGrupo()">
            </chat-header>
            <div class="chat-content flex"
                 infiniteScroll
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 (scrolled)="onScrollChatList($event)">
                <chat-list
                    *cdkIfOnDom [@animateStagger]="{value:'50'}"
                    class="flex flex-col"
                    (chatClickHandler)="openChat($event)"
                    [chatList]="chatList"
                    [chatOpen]="chatOpen"
                    [loading]="chatLoading">
                </chat-list>
            </div>
        </mat-sidenav>
        <!-- /LEFT SIDENAV-->
        <!-- RIGHT SIDENAV-->
        <mat-sidenav class="sidenav sidenav-right" position="end" opened="true" mode="side"
                     [disableClose]="true"
                     *ngIf="!!chatOpen && activeCard === 'chat-mensagem-list'">
            <div class="chat flex flex-col flex-1">
                <chat-mensagem-header class="chat-header"
                    [chat]="chatOpen"
                    (fecharChatHandler)="fecharChat()"
                    (chatFormHandler)="chatForm($event)"
                    (sairChatHandler)="removerParticipante($event)"
                    (excluirChatHandler)="excluirChat($event)"
                    (chatParticipantesHandler)="chatParticipantes($event)">
                </chat-mensagem-header>
                <div class="chat-content"
                     #chatMensagemScroll
                     cdkScrollbar
                     infiniteScroll
                     [scrollWindow]="false"
                     [infiniteScrollUpDistance]="3.5"
                     [infiniteScrollThrottle]="50"
                     (scrolledUp)="onScrollUpChatMessageList($event)"
                     (scroll)="onScrollChatMessageList($event)">
                    <chat-mensagem-list
                        [chat]="chatOpen"
                        [errors]="chatParticipanteErrors"
                        [loading]="(chatMensagemLoading$ | async)"
                        [chatMensagens]="chatMensagens">
                    </chat-mensagem-list>
                </div>
                <div class="chat-footer flex flex-0 flex-col">

                    <form class="chat-mensagem-form flex flex-0 flex-row justify-start items-center content-center" name="chatMensagemForm"
                          [formGroup]="chatMensagemForm"
                          (ngSubmit)="enviarMensagem(chatOpen)"
                          (keydown.enter)="enviarMensagem(chatOpen); $event.preventDefault();">

                        <mat-form-field class="message-text flex" floatLabel="auto" appearance="fill" [subscriptSizing]="'dynamic'" [hideRequiredMarker]="true">

                            <textarea matInput placeholder="Escreva sua mensagem..."
                                      shouldLabelFloat="false"
                                      formControlName="mensagem"
                                      #mensagem
                                      maxlength="150" [rows]="1" [cdkTextareaAutosize]="false"></textarea>
                        </mat-form-field>

                        <button class="send-message-button w-32 h-32 text-4xl leading-none pr-2" mat-icon-button type="submit" aria-label="Enviar Mensagem">
                            <mat-icon class="text-secondary">send</mat-icon>
                        </button>

                    </form>
                </div>
            </div>
        </mat-sidenav>
        <!-- /RIGHT SIDENAV-->
        <!-- CHAT GROUP FORM SIDENAV-->
        <mat-sidenav class="sidenav sidenav-right" position="start" opened="true" mode="side"
                     [disableClose]="true"
                     *ngIf="activeCard === 'chat-grupo-form'">
            <chat-grupo-form
                (cancelFormHandler)="cancelChatGrupoForm()"
                (chatFormHandler)="chatForm($event)"
                (chatParticipantesHandler)="chatParticipantes($event)"
                (removerParticipanteHandler)="removerParticipante($event)"
                (imagemCapaCroppedHandler)="uploadImgCapaChat($event)"
                (excluirChatHandler)="excluirChat($event)"
                (saveHandler)="salvarChat($event)"
                [chat]="chatOpen"
                [capa]="chatFormCapa"
                [saving]="chatFormSaving"
                [errors]="chatFormErrors || chatParticipanteErrors">
            </chat-grupo-form>
        </mat-sidenav>
        <!-- /CHAT GROUP FORM SIDENAV-->
        <!-- CHAT PARTICIPANTES SIDENAV-->
        <mat-sidenav class="sidenav sidenav-right" position="start" opened="true" mode="side"
                     [disableClose]="true"
                     *ngIf="activeCard === 'chat-participantes-list'">
            <chat-participantes-list
                (fecharHandler)="fecharParticipantes()"
                (chatParticipantesHandler)="chatParticipantes($event)"
                (chatFormHandler)="chatForm($event)"
                (adicionarParticipanteHandler)="adicionarParticipante($event)"
                (tornarAdministradorHandler)="tornarAdministrador($event)"
                (removerParticipanteHandler)="removerParticipante($event)"
                (excluirChatHandler)="excluirChat($event)"
                (scrollDownHandler)="onScrollDownChatParticipante()"
                [chat]="chatOpen"
                [chatParticipanteList]="chatParticipanteList"
                [saving]="chatParticipanteSaving"
                [loading]="chatParticipanteLoading"
                [errors]="chatParticipanteErrors">
            </chat-participantes-list>
        </mat-sidenav>
        <!-- /CHAT PARTICIPANTES SIDENAV-->
    </mat-sidenav-container>
</div>
