import {Widget} from 'widgets/widget';

const widget = new Widget();
widget.nome = 'Intimações';
widget.ordem = 50;
widget.module = () => import('modules/judicial/app/main/apps/intimacoes/widget/widget-intimacoes.module').then(m => m.WidgetIntimacoesModule);
widget.role = 'ROLE_DISTRIBUIDOR_JUDICIAL';

export const widgetConfig =
    [
        widget
    ];
