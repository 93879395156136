import {Type, Transform, Exclude} from 'class-transformer';
import {PrioridadeJudicial, ProcessoJudicial} from './';

export class VinculacaoPrioridadeJudicial {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    @Type(() => PrioridadeJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    prioridadeJudicial: PrioridadeJudicial;

    @Type(() => ProcessoJudicial)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processoJudicial: ProcessoJudicial;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.prioridadeJudicial = null;
        this.processoJudicial = null;
    }
}
