import {Component, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { combineLatest, filter, map, Subject, takeUntil } from 'rxjs';
import packageInfo from '../../../../../package.json';

import {CdkConfigService} from '@cdk/services/config.service';
import {navigation} from 'app/navigation/navigation';
import {CdkMediaWatcherService} from '../../../../@cdk/utils/media-watcher.service';
import {CdkPlatformService} from '../../../../@cdk/utils/platform.service';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    cdkConfig: any;
    navigation: any;
    chatOpen: boolean = false;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;

    mobileMode: boolean;
    // Private
    private _unsubscribeAll: Subject<any>;
    private innerWidth: any;

    constructor(
        private _cdkConfigService: CdkConfigService,
        private _cdkMediaWatcherService: CdkMediaWatcherService,
        @Inject(DOCUMENT) private _document: any,
        private _renderer2: Renderer2,
        private _cdkPlatformService: CdkPlatformService
    ) {
        // Set the defaults
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.innerWidth = window.innerWidth;
        this.mobileMode = innerWidth <= 600;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the theme and scheme based on the configuration
        combineLatest([
            this._cdkConfigService.config$,
            this._cdkMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            map(([config, mql]) => {

                const options = {
                    scheme: config.scheme,
                    theme : config.colorTheme
                };

                // If the scheme is set to 'auto'...
                if ( config.scheme === 'auto' )
                {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            })
        ).subscribe((options) => {

            // Store the options
            this.scheme = options.scheme;
            this.theme = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this._cdkConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.cdkConfig = config;

                // Update the layout
                this._updateLayout();
            });

        // Set the app version
        this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'supp-version', packageInfo.version);

        // Set the OS name
        this._renderer2.addClass(this._document.body, this._cdkPlatformService.osName);

        this.innerWidth = window.innerWidth;
        this.mobileMode = innerWidth <= 600;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the selected layout
     */
    private _updateLayout(): void
    {
        // Get the current activated route
        // Set the layout from the config
        this.layout = this.cdkConfig.layout.style;
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void
    {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void
    {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if ( className.startsWith('theme-') )
            {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }

    toogleChat(isOpen: boolean): void {
        this.chatOpen = isOpen;
    }
}
