import {Topico} from 'ajuda/topico';

const topicoCreate = new Topico();
topicoCreate.titulo = 'Criar modalidade integracao tipo indicador';
topicoCreate.descricao = 'Criar modalidade de integração do tipo indicador';
topicoCreate.path = 'admin/modalidade-integracao-tipo-indicador/editar/criar$';
topicoCreate.module = (): Promise<any> => import('./ajuda-create.module').then(m => m.AjudaCreateModule);

const topicoList = new Topico();
topicoList.titulo = 'Listar modalidades integracao tipo indicador';
topicoList.descricao = 'Listar modalidades de integração de tipo indicador';
topicoList.path = 'admin/modalidade-integracao-tipo-indicador/listar$';
topicoList.module = (): Promise<any> => import('./ajuda-list.module').then(m => m.AjudaListModule);

const topicoEdit = new Topico();
topicoEdit.titulo = 'Editar modalidade integracao tipo indicador';
topicoEdit.descricao = 'Editar modalidade e integração de tipo indicador';
topicoEdit.path = 'admin/modalidade-integracao-tipo-indicador/editar/\\d+$';
topicoEdit.module = (): Promise<any> => import('./ajuda-edit.module').then(m => m.AjudaEditModule);

export const topicosConfig = [ topicoCreate, topicoList, topicoEdit ];
