<div class="navbar-header" [ngClass]="cdkConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" [src]="_cdkConfigService.icone">
        <span class="logo-text">{{_cdkConfigService.sigla}}</span>
    </div>

    <button mat-icon-button class="toggle-sidebar-folded lt-lg:hidden"
            (click)="toggleSidebarFolded()">
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened lg:hidden"
            (click)="toggleSidebarOpened()">
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" cdkScrollbar [cdkScrollbarOptions]="{suppressScrollX: false}"
     [ngClass]="cdkConfig.layout.navbar.primaryBackground">
    <cdk-navigation layout="vertical"></cdk-navigation>
</div>
