import {Type, Transform, Exclude} from 'class-transformer';

export class ClasseNacional {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    nome: string;

    situacao?: string;

    codigoNacional?: number;

    codigoNacionalPai?: number;

    natureza?: string;

    dispositivoLegal?: string;

    artigo?: string;

    sigla?: string;

    siglaAntiga?: string;

    poloAtivo?: string;

    poloPassivo?: string;

    numeracaoPropria?: string;

    glossario?: string;

    lft?: number;

    lvl?: number;

    rgt?: number;

    root?: number;

    hasChild?: any;

    children?: any;

    expandable?: boolean;

    level?: number;

    @Type(() => ClasseNacional)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    parent: ClasseNacional;

    justEs1Grau?: string;

    justEs2Grau?: string;

    justEsJuizadoEs?: string;

    justEsTurmas?: string;

    justEs1GrauMil?: string;

    justEs2GrauMil?: string;

    justEsJuizadoEsFp?: string;

    justTuEsUn?: string;

    justFed1Grau?: string;

    justFed2Grau?: string;

    justFedJuizadoEs?: string;

    justFedTurmas?: string;

    justFedNacional?: string;

    justFedRegional?: string;

    justTrab1Grau?: string;

    justTrab2Grau?: string;

    justTrabTst?: string;

    stf?: string;

    stj?: string;

    cjf?: string;

    cnj?: string;

    justMilUniao1Grau?: string;

    justMilUniaoStm?: string;

    justMilEst1Grau?: string;

    justMilEstTjm?: string;

    justElei1Grau?: string;

    justElei2Grau?: string;

    justEleiTse?: string;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.nome = null;
        this.situacao = null;
        this.codigoNacional = null;
        this.codigoNacionalPai = null;
        this.natureza = null;
        this.dispositivoLegal = null;
        this.artigo = null;
        this.sigla = null;
        this.siglaAntiga = null;
        this.poloAtivo = null;
        this.poloPassivo = null;
        this.numeracaoPropria = null;
        this.glossario = null;
        this.lft = null;
        this.lvl = null;
        this.rgt = null;
        this.root = null;
        this.parent = null;
        this.justEs1Grau = null;
        this.justEs2Grau = null;
        this.justEsJuizadoEs = null;
        this.justEsTurmas = null;
        this.justEs1GrauMil = null;
        this.justEs2GrauMil = null;
        this.justEsJuizadoEsFp = null;
        this.justTuEsUn = null;
        this.justFed1Grau = null;
        this.justFed2Grau = null;
        this.justFedJuizadoEs = null;
        this.justFedTurmas = null;
        this.justFedNacional = null;
        this.justFedRegional = null;
        this.justTrab1Grau = null;
        this.justTrab2Grau = null;
        this.justTrabTst = null;
        this.stf = null;
        this.stj = null;
        this.cjf = null;
        this.cnj = null;
        this.justMilUniao1Grau = null;
        this.justMilUniaoStm = null;
        this.justMilEst1Grau = null;
        this.justMilEstTjm = null;
        this.justElei1Grau = null;
        this.justElei2Grau = null;
        this.justEleiTse = null;
        this.hasChild = null;
        this.children = null;
        this.expandable = null;
        this.level = null;
    }
}
