import {Exclude} from 'class-transformer';

export class FonteDados {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    nome?: string;

    sigla?: string;

    ambiente?: string;

    ativo?: boolean;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.nome = null;
        this.sigla = null;
        this.ambiente = null;
        this.ativo = true;
    }
}






