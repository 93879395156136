import {AbstractComparisonOperator} from '@cdk/components/criteria/operators/abstract-comparison-operator';
import {ExpressionPositionMiddle, ExpressionPositions} from '@cdk/components/criteria/operators/index';

export default class EndsWith extends AbstractComparisonOperator
{
    public readonly expression: string = 'like:%arg';

    constructor(
        literalOperator: string = 'termina com',
        expressionPosition: ExpressionPositions = ExpressionPositionMiddle
    ) {
        super(
            literalOperator,
            expressionPosition
        );
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public checkExpression(expression: string): boolean {
        const regex: RegExp = new RegExp(/^like:%(?!.*%).*$/);
        return regex.test(expression);
    }

    /**
     * Returns expression value.
     * @param expression
     */
    public getValueFromExpression(expression: string): string {
        const regex: RegExp = new RegExp(/^like:%(?!.*%)(.*)$/);
        const result = regex.exec(expression);
        return result ? result[1] : null;
    }
}
